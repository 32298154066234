import React from "react"
import ProductPage from "../../layouts/product-page"

const model = {
  eng: {
    id: "kids",
    model: "P13  7’’ KIDS WiFi",
    os: "Android  O（Go edition)",
    chipset: "MT8321 Quad-core 1.3GHz",
    band: "N/A",
    display:
      "7.0” 1024x600 pixels WSVGA, TFT 16M color display, Capacitive touch screen, Multi-touch 5 points",
    memory: "16GB ROM+ 1G  RAM, External storage up to 128GB",
    dimensions: "189.5x 111 x 9.15mm, 245g",
    cameras: "front: 5MP | rear: 2MP FF",
    video:
      "capture: 720p 30fps | playback: 720p 30fps | streaming: 1080p 30fps | formats: H.263, H.264 AVC, H.265 HEVC, MPEG-4 SP, VP8, VP9, etc",
    audio:
      "speakers: 1 speaker | codes: AAC LC, HE-AACv1 (AAC+)， HE-AACv2 (enhanced AAC+), AAC ELD (enhanced low delay AAC), AMR-NB, AMR-WB, FLAC, MP3, MIDI, Vorbis, PCM/WAVE",
    connectivity:
      "Wi-Fi 802.11 b/g/n, Bluetooth 4.2, Micro USB 2.0 high speed, FM, Wi-Fi Direct, GPS",
    sensor: "G-sensor",
    interface: "Micro USB , 3.5mm jack",
    battery: "2580mAh, 430 hours standby time",
    cmf: "Raw material",
    accessories:
      "default: 5V/0.55A charger, USB cable, QG, Hotline Leaflet, LCD Sticker",
    image:
      "https://lh3.googleusercontent.com/pw/ACtC-3d1UsD2B7b-S4JUUk_IEDaHsWWot5aZ4O0MZxBCc9N9GvMuC6LzMABBDSy1H48ua2aM3NbNcMf1zhwI53CRb92EUX8LJM2MbqojzD6gdPWzq12AozW5g1QFmJXO0_TNG1JabEXg8FoYmXjLY_jUXTk=d",
  },
  esp: {
    id: "kids",
    model: "P13  7’’ KIDS WiFi",
    os: "Android  O（Go edition)",
    chipset: "MT8321 Quad-core 1.3GHz",
    band: "N/A",
    display:
      "7.0” 1024x600 pixels WSVGA, TFT 16M color display, Capacitive touch screen, Multi-touch 5 points",
    memory: "16GB ROM+ 1G  RAM, External storage up to 128GB",
    dimensions: "189.5x 111 x 9.15mm, 245g",
    cameras: "front: 5MP | rear: 2MP FF",
    video:
      "capture: 720p 30fps | playback: 720p 30fps | streaming: 1080p 30fps | formats: H.263, H.264 AVC, H.265 HEVC, MPEG-4 SP, VP8, VP9, etc",
    audio:
      "speakers: 1 speaker | codes: AAC LC, HE-AACv1 (AAC+)， HE-AACv2 (enhanced AAC+), AAC ELD (enhanced low delay AAC), AMR-NB, AMR-WB, FLAC, MP3, MIDI, Vorbis, PCM/WAVE",
    connectivity:
      "Wi-Fi 802.11 b/g/n, Bluetooth 4.2, Micro USB 2.0 high speed, FM, Wi-Fi Direct, GPS",
    sensor: "G-sensor",
    interface: "Micro USB , 3.5mm jack",
    battery: "2580mAh, 430 hours standby time",
    cmf: "Raw material",
    accessories:
      "default: 5V/0.55A charger, USB cable, QG, Hotline Leaflet, LCD Sticker",
    image:
      "https://lh3.googleusercontent.com/pw/ACtC-3d1UsD2B7b-S4JUUk_IEDaHsWWot5aZ4O0MZxBCc9N9GvMuC6LzMABBDSy1H48ua2aM3NbNcMf1zhwI53CRb92EUX8LJM2MbqojzD6gdPWzq12AozW5g1QFmJXO0_TNG1JabEXg8FoYmXjLY_jUXTk=d",
  },
}
export default () => <ProductPage model={model} />
