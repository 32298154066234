import React from "react"
import { connect } from "react-redux"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../components/layout.css"
import {
  Menu,
  Grid,
  Image,
  Header,
  Accordion,
  Icon,
  Table,
  Card,
  Flag,
} from "semantic-ui-react"
import CardCollection from "../components/products-cards"

class ProductPage extends React.Component {
  render() {
    const { lang, model } = this.props
    const langSel = lang === "english" ? "eng" : "esp"
    const modelSel = model[langSel]
    return (
      <Layout>
        <CardCollection />
        <Grid style={{ marginTop: "2rem" }} stackable>
          <Header style={{ marginTop: "1rem", margin: "auto" }} size="huge">
            {modelSel.model}
          </Header>
          <Grid.Row key={modelSel.id} id={modelSel.id}>
            <Grid.Column style={{ textAlign: "center" }} width={8}>
              <Image
                style={{
                  marginTop: "1rem",
                  height: "auto",
                  width: "500px",
                  display: "block",
                  margin: "auto",
                }}
                src={modelSel.image}
              />
            </Grid.Column>
            <Grid.Column width={8}>
              <Table basic="very" style={{ width: "400px" }}>
                <Table.Body>
                  {Object.entries(modelSel).map(([k, v], i) => {
                    return k !== "image" && k !== "id" && k !== "model" ? (
                      <Table.Row key={k}>
                        <Table.Cell>{k.toUpperCase()}</Table.Cell>
                        <Table.Cell>{v}</Table.Cell>
                      </Table.Row>
                    ) : (
                      <React.Fragment key={k}></React.Fragment>
                    )
                  })}
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Layout>
    )
  }
}

export default connect(
  state => ({
    lang: state.lang,
    page: state.page,
  }),
  null
)(ProductPage)
